<template>
 <div class="mod-config">
    <el-tabs v-model="activeName"  >
      <el-tab-pane label="用户信息" name="userInfo">
         <el-form :model="dataForm" v-loading="loading" :rules="dataRule" ref="dataForm" class="user-form" label-width="80px">
	         <el-form-item label="用户账号:"  >
	            <span class="el-form-item__label">{{dataForm.userNo}} </span>
	          </el-form-item> 
	          <el-form-item label="注册时间:" size="mini"  > 
	             <span class="el-form-item__label">{{dataForm.createTime}} </span>
	          </el-form-item>
	          <el-form-item label="用户姓名:" prop="username" >
	            <el-input v-model="dataForm.username" placeholder="用户姓名" style="width: 50%;" maxlength="30"></el-input>
	          </el-form-item>
	         
	          <el-form-item label="性别:"    >
	              <el-radio-group v-model="dataForm.sex">
	                <el-radio :label="1">男</el-radio>
	                <el-radio :label="2">女</el-radio>
	              </el-radio-group>
	          </el-form-item>
	         
	          <el-form-item label="手机号:" prop="mobile">
	            <el-input v-model="dataForm.mobile" placeholder="手机号" style="width: 50%;" maxlength="11"></el-input>
	          </el-form-item>
	         

       
        	</el-form> 
        	<div  class="mod-footer"> 
		      <el-button type="primary" :disabled="loading" @click="dataFormSubmit()">确定</el-button>
		    </div>
      </el-tab-pane>
      <el-tab-pane label="修改密码" name="userPassword">
        <el-form :model="passForm" :rules="passRule" ref="passForm" class="user-form" v-loading="loading"  label-width="80px">
	      <el-alert  type="warning" :closable="false">密码修改后需要重新登录!</el-alert>
	      <el-form-item label="用户账号:">
	        <span>{{ dataForm.userNo }}</span>
	      </el-form-item>
	      <el-form-item label="原密码:" prop="password">
	        <el-input type="password" v-model="passForm.password"></el-input>
	      </el-form-item>
	      <el-form-item label="新密码:" prop="newPassword">
	        <el-input type="password" v-model="passForm.newPassword"></el-input>
	      </el-form-item>
	      <el-form-item label="确认密码:" prop="confirmPassword">
	        <el-input type="password" v-model="passForm.confirmPassword"></el-input>
	      </el-form-item>
	    </el-form>
	    <div  class="mod-footer"> 
	      <el-button type="primary" @click="passwordSubmit()">确定</el-button>
	    </div>

      </el-tab-pane> 
      
    </el-tabs>
    
    
  </div>
</template>

<script>  
  import { clearLoginInfo } from '@/utils' 
  import { encryptDes } from '@/utils/des'
  import { validatorPassword } from '@/utils/validate'
  import { userInfo ,updatePassword ,updateInfo } from '@/api/sys/user'
  export default {
    components: {
     
    },
    data () {
      
        var validatePass = (rule, value, callback) => {
	      if (!validatorPassword(value)) {
	        callback(new Error('密码要求长度8~20位,并且不能为纯数字或纯字母!'))
	      } else {
	        callback()
	      }
	    }
	    var validateConfirmPassword = (rule, value, callback) => {

	      if (this.passForm.newPassword != value) {
	        callback(new Error('确认密码与新密码不一致'))
	      } else {
	        callback()
	      }
	    }
      return {
        loading: false,
        activeName: 'userInfo',
        passForm: {
          password: '',
          newPassword: '',
          confirmPassword: ''
        },
        dataForm: {
          userId: '', 
          sex: 0, 
          mobile: '',
          username: '',
          userNo:'', 
          createTime: '',
          lastLoginTime: '' , 
          roleName: '' , // 后台角色 
        } ,
        passRule: {
            password: [
	          { required: true, message: '原密码不能为空', trigger: 'blur' }
	        ],
	        newPassword: [
	          { required: true, message: '新密码不能为空', trigger: 'blur' },
	          { validator: validatePass, trigger: 'blur' }
	        ],
	        confirmPassword: [
	          { required: true, message: '确认密码不能为空', trigger: 'blur' },
	          { validator: validateConfirmPassword, trigger: 'blur' },
	          { validator: validatePass, trigger: 'blur' }
	        ]
        },
        dataRule: {
          username: [
            { required: true, message: '用户名不能为空', trigger: 'blur' }
          ] 
        }
      }
    },
    activated () {
        this.init()
         
    },
    methods: {
    	init() {
    		this.loading = true 
	        userInfo().then(({data}) => {
	          //console.log('infoData', data)
	          if (data && data.code === 0) {

	            debugger
	               this.dataForm.userId = data.data.userId 
	               this.dataForm.sex = data.data.sex
	               this.dataForm.mobile = data.data.mobile
	               this.dataForm.username = data.data.username
	               this.dataForm.userNo = data.data.sysUser.userNo
	               this.dataForm.lastLoginTime = data.data.lastLoginTime
	               this.dataForm.createTime = data.data.sysUser.createTime

	                this.dataForm.roleName = data.data.roleName
	               
	            
	          }
	        }).finally(()=>{
	        	this.loading = false
	        })
    	},
      	passwordSubmit() {
      		this.$refs['passForm'].validate((valid) => {
	          if (valid) {
	          	this.$confirm(`确定进行更新操作?`, '提示', {
		          confirmButtonText: '确定',
		          cancelButtonText: '取消',
		          type: 'warning'
		        }).then(() => {
		          	this.loading = true
		            //密码加密 
		            const o_pass = encryptDes(this.passForm.password)
		            const n_pass = encryptDes(this.passForm.newPassword)
 
		            updatePassword(o_pass , n_pass).then(({data}) => {
		              if (data && data.code === 0) {
		                this.$message({
		                  message: '修改成功',
		                  type: 'success',
		                  duration: 1500 
		                })
		                this.$nextTick(() => {
		                    clearLoginInfo()
		                    this.$router.replace({ name: 'login' })
		                })
		              } else {
		                this.$message.error(data.msg)
		              }
		            }).finally(()=>{
		            	this.loading = false
		            })
		        }).catch(() => {})

	          	
	          }
	        })
      	},
      	dataFormSubmit() {
      		this.$refs['dataForm'].validate((valid) => {
	         if (valid) {
	         	this.$confirm(`确定进行更新操作?`, '提示', {
		          confirmButtonText: '确定',
		          cancelButtonText: '取消',
		          type: 'warning'
		        }).then(() => {
		        	this.loading=true 
		            updateInfo({
		                'userId': this.dataForm.userId  ,
		                'username': this.dataForm.username, 
		                'mobile': this.dataForm.mobile,
		                'sex': this.dataForm.sex  
		            }).then(({data}) => {
		              if (data && data.code === 0) {

		                this.$message({
		                  message: '操作成功',
		                  type: 'success',
		                  duration: 500 
		                })
		              } else {
		                this.$message.error(data.msg)
		              }
		            }).finally(()=>{
		               this.loading = false
		            })

		        }).catch(() => {})

	           
	          }
	        })
      	}
    }
  }
</script>
<style>
.user-form {
	width: 50% ;
	margin: auto;
}
</style>